import React from 'react';
import { FaIconName } from 'react-icons/fa';

import "../styles/AboutUs.css";

const About = () => {

    return (
        <div id="about" className="App2">
            <header className="App-header">
                <h1>Welcome to Sri Vasudevamurthy Tulasi Charitable Trust (R)</h1>
            </header>
            <div className="row">
                <div className="imgWrapper">
                    <img src="/images/image2.jpg" alt="image" />
                </div>
                <div className="contentWrapper">
                    <div className="content">
                        <span className="textWrapper">
                            {/* <span>About US</span> */}
                            <h2>About SVTC</h2>
                        </span>
                        {/* <h2>About </h2> */}
                        <p>
                            We support economically poor students ,but not students who are academically POOR. Educational scholarship is only given to students who are hardworking, diligent, meticulous, consistent and have a purpose of performance in life. Preference for this offer is for BRAHMINS – Madhwa’s / Smartha’s / Vyshnav’s. We also honour poor students who have secured 90% & above in any Board Exam,with a PRATHIBA PURASKARA AWARD
                        </p>

                        <h3>Objectives of the Trust</h3>

                        <ul className="icon-list">
                            <li><i className="fas fa-check-circle"></i>
                                To establish, maintain, run, develop, improve, extend, grant, donate for and to aid in the establishment, maintenance, improvement and extension of schools, colleges, polytechnics and other educational institutions including vocational training centre’s, research centres and audio-visual educational centre’s and hostels for students pursuing their studies.
                            </li>

                            <li><i className="fas fa-check-circle"></i>
                                To give donations to educational institutions.
                            </li>

                            <li><i className="fas fa-check-circle"></i>
                                To constitute scholarships to poor and deserving students to enable them to continue their studies and to give grants for fees and other charges or re-imbursement for costs of books, instruments and other educational aids for educational purpose.
                            </li>
                            
                            <li><i className="fas fa-check-circle"></i>
                                To help establishments of student’s hostel and to give other assistances for poor and deserving students to find in-expensive living accommodation to enable them to prosecute higher studies.
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default About;