// DonatePage.js
import React from 'react';
import "../styles/donate.css";

const DonatePage = () => {
  return (
    <div className="donate-page">
      <div className="donate-page__text heading1">
        <h1>Donate</h1>
        <br></br>
        <p>
        Your generous contribution plays a vital role in supporting our mission to provide educational opportunities to deserving students. With your donation, you're not just offering financial assistance, but you're investing in the future of bright minds, empowering them to pursue their dreams and achieve success. Every donation, no matter the size, makes a significant difference in the lives of these students. Together, we can make a lasting impact. Thank you for your support.
        </p>
      </div>
      <div className="donate-page__bank-details">
        <h2 className='heading-donate'>Bank Details</h2> 
        <br></br>
        <p>
          Account Name: John Doe <br />
          Account Number: 123456789 <br />
          Bank Name: Acme Bank <br />
          SWIFT Code: ABCL1234
        </p>
      </div>
    </div>
  );
};

export default DonatePage;