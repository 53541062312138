import React, { useState } from 'react';
import "../styles/Contactus.css";
// import QRCode from 'qrcode.react';
import axios from 'axios';
import { FaEnvelope, FaPhone, FaMapMarkerAlt } from 'react-icons/fa';

function Contactus() {
  const [fullName, setFullName] = useState('');
  // const [lastName, setLastName] = useState('');
  const [email, setEmail] = useState('');
  const [phoneNumber, setPhoneNumber] = useState('');
  const [message, setMessage] = useState('');

  // State variables for field validity
  const [fullNameValid, setFullNameValid] = useState(true);
  // const [lastNameValid, setLastNameValid] = useState(true);
  const [emailValid, setEmailValid] = useState(true);
  const [phoneNumberValid, setPhoneNumberValid] = useState(true);
  const [messageValid, setMessageValid] = useState(true);
  const handleSendMessage = (e) => {
    e.preventDefault(); // Prevent form submission if any field is invalid

    // Check if all fields are valid
    if (fullName && email && phoneNumber && message) {
      const messageData = {
        fullName,
        // lastName,
        email,
        phoneNumber,
        message
      };

      // Send the message to the backend server
      axios.post('https://your-backend-server.com/api/send-message', messageData)
        .then(response => {
          console.log('Message sent successfully:', response.data);
          // Clear form fields
          setFullName('');
          // setLastName('');
          setEmail('');
          setPhoneNumber('');
          setMessage('');
        })
        .catch(error => {
          console.error('Error sending message:', error);
        });
    } else {
      // If any field is empty, set its validity to false
      setFullNameValid(!!fullName);
      // setLastNameValid(!!lastName);
      setEmailValid(!!email);
      setPhoneNumberValid(!!phoneNumber);
      setMessageValid(!!message);
    }
  };

  return (
    <div id='contact' className="heading1">
      <h1>Contact</h1>

      <div className="ContactUsPage">
        <div className="box ContactInfo">
          <h3>Contact information</h3>

          <p>If you have any questions, feedback, or inquiries, feel free to reach out to us. 
            <br></br>Our team is here to assist you with any assistance you may need. 
            <br></br>We value your input and are dedicated to providing the best possible service to our customers.</p>

<br></br>
          <div>
            <div style={{ display: 'flex', alignItems: 'center' }}>
              <FaEnvelope size={20} color="grey" />
              <span style={{ marginLeft: '2rem' }}><p>Email: svtctrust@gmail.com</p>
                {/* <p>Email: trust@example.com</p> */}
              </span>
            </div>
            <br />
            <div style={{ display: 'flex', alignItems: 'center' }}>
              <FaMapMarkerAlt size={20} color="grey" />
              <span style={{ marginLeft: '2rem' }}><p style={{ marginBottom: '1rem' }}>Address:
              </p><p>#60, 4th Cross,</p>
                <p>Malleswaram,</p>
                <p>Bangalore 560 003</p>
                <p>Karnataka State, INDIA</p></span>
            </div>
            <br />

            <div style={{ display: 'flex', alignItems: 'center' }}><FaPhone size={20} color="grey" />
              <span style={{ marginLeft: '2rem' }}><p>Phone: +91-9036435039</p></span></div>
            <br />

          </div>
        </div>

        <div className='box'>
          {/* Form */}
          <form className="contact_form_box" onSubmit={handleSendMessage}>
            <div>
              <label htmlFor="fullName">Full Name<span className="required">*</span></label>
              <input
                type="text"
                id="fullName"
                placeholder="Full Name"
                value={fullName}
                onChange={(e) => {
                  setFullName(e.target.value);
                  setFullNameValid(!!e.target.value);
                }}
              />
              {!fullNameValid && <p className="error-message-contact">Please enter your full name</p>}
            </div>
            {/* Repeat the above structure for each input field */}


            <div>
              <label htmlFor="email">Email Address<span className="required">*</span></label>
              <input
                type="email"
                id="email"
                placeholder="Email"
                value={email}
                onChange={(e) => {
                  setEmail(e.target.value);
                  setEmailValid(!!e.target.value);
                }}
              />
              {!emailValid && <p className="error-message-contact">Please enter valid email address</p>}
            </div>
            <div>
              <label htmlFor="phoneNumber">Mobile Number<span className="required">*</span></label>
              <input
                type="text"
                id="phoneNumber"
                placeholder="Phone Number"
                value={phoneNumber}
                onChange={(e) => {
                  setPhoneNumber(e.target.value);
                  setPhoneNumberValid(!!e.target.value)
                }}
              />
              {!phoneNumberValid && <p className="error-message-contact">Please enter your phone number</p>}
            </div>
            <div>
              <label htmlFor="message">Message<span className="required">*</span></label>
              <textarea
                id="message"
                placeholder="Message"
                value={message}
                onChange={(e) => {
                  setMessage(e.target.value);
                  setMessageValid(!!e.target.value);
                }}
              />
              {!messageValid && <p className="error-message-contact">Please enter a message</p>}
            </div>

            <div className='contact-button'>

              <button type="submit" disabled={!fullNameValid || !emailValid || !phoneNumberValid || !messageValid}>Send Message</button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
}

export default Contactus;
