import React from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "../styles/About_trustees.css";

function AboutTrustees() {
    return (
        <div className="trustees_container">
            <header className="App-header">
                <h1>Trustees</h1>
            </header>
            <div className="container01">
                <div className="image-container01">
                    <div className="row01">
                        {images.slice(0, 3).map((image, index) => (
                            <div className="column01" key={index}>
                                <img className="image01" src={image.img} alt={image.alt} />
                                <p className="text01">{image.name}</p>
                                <p className="text02">{image.about}</p>
                            </div>
                        ))}
                    </div>


                    <div className="row01">
                        {images.slice(3).map((image, index) => (
                            <div className="column01" key={index}>
                                <img className="image01" src={image.img} alt={image.alt} />
                                <p className="text01">{image.name}</p>
                                <p className="text02">{image.about}</p>
                            </div>
                        ))}
                    </div>
                </div>
            </div>

        </div>
    );
};

// const data = [
const images = [
    {
        name: 'Late L V. Tulasi & Late L S Vasudeva Murthy',
        img: '/images/trustee_1.jpg',
        about: 'Founder Trustee '
    },
    {
        name: 'L V Ravi',
        img: '/images/trustee_2.jpg',
        about: 'Managing Trustee'
    },
    {
        name: 'Mamatha Ravi',
        img: '/images/trustee_3.jpg',
        about: 'Trustee '
    },
    {
        name: 'Monisha Ravi',
        img: '/images/trustee_4.jpg',
        about: 'Trustee '
    },
    {
        name: 'Nalini Ramdas',
        img: '/images/trustee_5.jpg',
        about: 'Trustee '
    },
    {
        name: 'Nandini Jai',
        img: '/images/trustee_6.jpg',
        about: 'Trustee'
    },


]

export default AboutTrustees;


