import React from 'react';
import Banner from './Banner';
import {slides } from './Data';
import MultiStepForm from './MultiStepForm';

const MergedPages = () => {
  return (
    <div className="merged-pages">
      <div className="page-container">
        {/* <Page1 /> */}
        <Banner images={slides}/>
      </div>
      <div className="page-container">
      <MultiStepForm/>
        {/* <Page2 /> */}
      </div>
    </div>
  );
};

export default MergedPages;