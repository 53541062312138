import PersonalDetails from './PersonalDetails';
// import Personal from './Personal';
// import AcademicDetails from './AcademicDetails';
import AcademicDetails from './AcademicDetails_new';
import FamilyForm2 from './FamilyForm2';
import BankDetails from './BankDetails';
import SubmitApplication from './submitApplication'
import { useState } from 'react';

function MultiStepForm() {
    const [step, setStep] = useState(1);

    const nextStep = () => {
        setStep(step + 1);
    };

    const previousStep = () => {
        setStep(step - 1);
    };

    const handleSubmit = () => {
        // Handle form submission
        setStep(step+1)
      };

    return (
        <div>
          {step === 1 && <PersonalDetails onNext={nextStep} />}
          {step === 2 && <AcademicDetails onNext={nextStep} onPrevious={previousStep} />}
          {step === 3 && <FamilyForm2 onPrevious={previousStep} onNext={nextStep} />}
          {step === 4 && <BankDetails onPrevious={previousStep} onSubmit={handleSubmit} />}
          {step === 5 && <SubmitApplication />}
        </div>
      );
    
}

export default MultiStepForm;

