export const slides = [
    {
        image: "/images/image1.jpg",
        title: "We support economically poor students ,but not students who are academically POOR. Preference for this offer is for BRAHMINS – Madhwa’s / Smartha’s / Vyshnav’s."
    },
    {
        image: "/images/image2.jpg",
        title: "We support economically poor students ,but not students who are academically POOR. Preference for this offer is for BRAHMINS – Madhwa’s / Smartha’s / Vyshnav’s."
    },
    {
        image: "/images/image3.jpg",
        title: "We support economically poor students ,but not students who are academically POOR. Preference for this offer is for BRAHMINS – Madhwa’s / Smartha’s / Vyshnav’s."
    },
]