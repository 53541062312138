import React from 'react';
import { useState, useEffect } from 'react';
// import '../styles/bank.css';
import axios from 'axios';

function BankDetails({ onPrevious, onSubmit }) {
    const [bank, setBank] = useState({
        name: "",
        number: "",
        ifsc: "",
        bankName: "",
        branchName: "",
        statement: null,
    });

    useEffect(() => {
        const fetchData = async () => {
            const user_id = localStorage.getItem("user_id")
            console.log(`userid from localstorage : ${user_id}`);
            const apiUrl = process.env.REACT_APP_API_BASE_URL;
            if (user_id) {
                let response
                try {
                    response = await axios.get(`${apiUrl}/application/getUserInfo/${user_id}`)
                } catch (e) {
                    console.log(`error fetching user details ${e}`)
                }
                const user = response.data
                if (user.bankDetail) {

                    setBank({
                        ...bank,
                        name: user.bankDetail.account_holder_name,
                        number: user.bankDetail.account_number,
                        ifsc: user.bankDetail.ifsc_code,
                        bankName: user.bankDetail.bank_name,
                        branchName: user.bankDetail.branch_name,
                    })
                    // setFormData({...formData, aadharNumber : user.basicDetail.aadharno  })
                    // setPermanentAddress(user.contactDetail.permanent_address)
                    // setCurrentAddress(user.contactDetail.address)
                }
            }
        }
        fetchData()

    }, [])

    const [formValid, setFormValid] = useState(false);

    const handleChange = (event) => {
        const { name, value } = event.target;
        setBank({
            ...bank,
            [name]: value,
        });
    };

    const handleFileChange = (event) => {
        const file = event.target.files[0];
        if (file.size > 1024 * 1024) { // Check if file size is greater than 1MB
            alert("File size should be less than 1MB.");
            event.target.value = null; // Reset the file input
        } else {
            setBank({
                ...bank,
                statement: file,
            });
        }
    };

    const handleSubmit = (event) => {
        event.preventDefault();
        const isFormValid = Object.values(bank).every(value => value !== "");
        if (isFormValid) {
            onSubmit();
        } else {
            alert("Please fill out all required fields.");
        }
    };

    const saveData = async () => {
        const user_id = localStorage.getItem("user_id")
        const apiUrl = process.env.REACT_APP_API_BASE_URL;
        const bankData = {
            user_id: user_id,
            account_holder: bank.name,
            account_number: bank.number,
            ifsc: bank.ifsc,
            bank_name: bank.bankName,
            branch_name: bank.branchName
        }

        try {
            const response = await axios.post(apiUrl+'/application/uploadBankDetails', bankData)
        } catch (error) {
            console.log(error);
        }

        const data = new FormData();
        data.append('userid', 'bhargav'); // change it to correct userid

        if (bank.statement !== null) {
            data.append('bank_account', bank.statement);
        }

        try {
            const response = await axios.post('http://127.0.0.1:3000/uploadFile', data);
            console.log(response.data);

        } catch (error) {
            console.log(error);
        }
    }

    return (
        <div id="bankform" className='heading'>
            <form onSubmit={handleSubmit}>
                <h2>Scholarship Application</h2>
                <div className='progress' style={{ position: "relative" }}>
                    <ul>
                        <li ><div id='step1'>Personal Details</div></li>
                        <li ><div id='step2' >Academic Details</div></li>
                        <li ><div id='step3'>Family Details</div></li>
                        <li><div id='step4'>Bank Details</div></li>
                    </ul>
                    <div className="progressdiv">
                        <progress value={1} color='blue' />
                    </div>
                </div>
                <div className="form2">
                    <label>
                        Bank Account Holder Name <span style={{ color: "red" }}>*</span><br />
                        <input
                            type="text"
                            placeholder="Name"
                            name="name"
                            value={bank.name}
                            onChange={handleChange}
                            required
                        />
                    </label>

                    <label>
                        Bank Account Number  <span style={{ color: "red" }}>*</span><br />
                        <input
                            type="text"
                            placeholder="Account Number"
                            name="number"
                            value={bank.number}
                            onChange={handleChange}
                            required
                        />
                    </label>

                    <label>
                        IFSC code  <span style={{ color: "red" }}>*</span><br />
                        <input
                            type="text"
                            placeholder="IFSC Code"
                            name="ifsc"
                            value={bank.ifsc}
                            onChange={handleChange}
                            required
                        />
                    </label>

                    <label>
                        Bank Name  <span style={{ color: "red" }}>*</span><br />
                        <input
                            type="text"
                            placeholder="Bank Name"
                            name="bankName"
                            value={bank.bankName}
                            onChange={handleChange}
                            required
                        />
                    </label>

                    <label>
                        Branch Name<br />
                        <input
                            type="text"
                            placeholder="Branch Name"
                            name="branchName"
                            value={bank.branchName}
                            onChange={handleChange}
                        />
                    </label>

                    <div className="form-group">
                        <div style={{ display: 'flex', alignItems: 'center' }}>
                            <label htmlFor="pucmarks">
                            Bank Statement / Bank Passbook / Bank Cheque
                                <span className="required">*</span></label>
                            <div>
                                <label htmlFor="" className="redLabel23">(only in pdf, png, jpg and jpeg format and max size should be 1MB)</label>
                            </div>
                        </div>
                        <input
                            type="file"
                            className='bank_file'
                            name="statement"
                            accept='.pdf,.png,.jpg,.jpeg'
                            onChange={handleFileChange}
                            required
                        />
                    </div>


                </div>

                <div className="button-container">
                    <button onClick={() => {
                        saveData();
                        onPrevious();
                    }} type="submit2">Previous</button>
                    <button onClick={() => {
                        saveData();
                        onSubmit();
                    }} id='btn' type="submit3">Submit</button>

                </div>
            </form >

        </div >
    );
}

export default BankDetails;
