import React, { useState } from 'react'
import "../styles/Header.css"


function Header() {
  const [active, setActive] = useState("nav__menu");
  const [icon, setIcon] = useState("nav__toggler");
  const navToggle = () => {
    if (active === "nav__menu") {
      setActive("nav__menu nav__active");
    } else setActive("nav__menu");

    if (icon === "nav__toggler") {
      setIcon("nav__toggler toggle");
    } else setIcon("nav__toggler");
  };

  return (
    <nav id="home" className="nav1">
      <a href="/" className="nav__brand">
      <img src="/images/logo.png"/>
      </a>
      <ul className={active}>
        <li className="nav__item">
          <a href="/" className="nav__link">
            Home
          </a>
        </li>
        <li className="nav__item">
          <a href="#about" className="nav__link">
             About
          </a>
        </li>
        <li className="nav__item">
          <a href="/contact" className="nav__link">
            Contact 
          </a>
        </li>
        <li className="nav__item">
          <a href=" /status" className="nav__link">
          Application Status
          </a>
        </li>
        <li className="nav__item">
          <a href=" /donate" className="nav__link">
          Donate
          </a>
        </li>
      </ul>
      <div onClick={navToggle} className={icon}>
        <div className="line1"></div>
        <div className="line2"></div>
        <div className="line3"></div>
      </div>
    </nav>
  );
}

export default Header;