import React from 'react';
import '../styles/Footer.css';

function Footer() {
  return (
    <div className="container-footer w-container">
      <div className="w-row">
        <div className="footer-column w-clearfix w-col w-col-4">
          {/* <img src="" alt="Logo" /> */}
          <h3 className="footer-failory-name"></h3>
          <p className="footer-description-failory">Sri Vasudevamurthy Tulasi Charitable Trust ( R)</p>
        </div>
        <div className="footer-column w-col w-col-8">
          <div className="w-row">
            <div className="w-col w-col-8">
              <div className="w-row">

                <div className="w-col w-col-5 w-col-small-6 w-col-tiny-5">
                  <h3 className="footer-titles">Address</h3>
                  <p className="footer-links1">
                    <p>L V RAVI - Managing Trustee</p>
                    <p># 60, 4TH CROSS,</p>
                    <p>MALLESWARAM,</p>
                    <p>BANGALORE 560 003</p>
                    <p>Karnataka State, INDIA</p>
                  </p>
                </div>

                <div className="w-col w-col-7 w-col-small-6 w-col-tiny-7">
                  <h3 className="footer-titles">Useful Links</h3>
                  <p className="footer-links">
                    <a href="" target="_blank" rel="noopener noreferrer"></a>
                    <a href="/">Home</a>
                    <span style={{ marginBottom: '10px', display: 'block' }}></span>
                    <a href="/status">Application Status</a>
                    <span style={{ marginBottom: '10px', display: 'block' }}></span>
                    <a href="/">Services</a>
                    <span style={{ marginBottom: '10px', display: 'block' }}></span>
                    <a href="/contact">Contact</a>
                  </p>
                </div>
              </div>
            </div>
            <div className="column-center-mobile w-col w-col-4">
              <h3 className="footer-titles1">Follow Us!</h3>
              <a href="" target="_blank" rel="noopener noreferrer" className="footer-social-network-icons w-inline-block">
                <img src="/images/download.png" width="24" alt="X icon" />
              </a>
              <a href="" target="_blank" rel="noopener noreferrer" className="footer-social-network-icons w-inline-block">
                <img src="\images\download_insta.jpg" width="24" alt="Instagram icon" />
              </a>
              <a href="" target="_blank" rel="noopener noreferrer" className="footer-social-network-icons w-inline-block">
                <img src="\images\download_facebook.png" width="23" alt="Facebook Icon" />
              </a>
              <a href="" target="_blank" rel="noopener noreferrer" className="footer-social-network-icons w-inline-block">
                <img src="\images\download_linkedin.png" width="23" alt="LinkedIn Icon" />
              </a>

              <p className="footer-description footer-links1">Mobile No.: <a href=""><strong className="link-email-footer">+91-9036435039</strong></a></p>
              <p className="footer-description footer-links1">Land Line: <a href=""><strong className="link-email-footer">+9180-23340203</strong></a></p>
              <p className="footer-description footer-links1">Email me at: <a href=""><strong className="link-email-footer">svtctrust@gmail.com</strong></a></p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Footer;
