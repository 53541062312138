import React, { useState, useEffect } from 'react';
import axios from 'axios';
import Banner from './Banner';
import { slides } from './Data';
import '../styles/Status.css'; // Ensure this file exists and contains the necessary styles

const Status = () => {
  const [applicationId, setApplicationId] = useState('');
  const [status, setStatus] = useState(null); // Holds the status returned from the server
  const [reason, setReason] = useState(null); // Holds the rejection reason if any
  const [error, setError] = useState(null); // Holds any error message
  const [showModal, setShowModal] = useState(false); // Controls the visibility of the modal
  const [aadhaarNumber, setAadhaarNumber] = useState(''); // Aadhaar number input for modal
  const [retrievedAppId, setRetrievedAppId] = useState(null); // Holds the retrieved application number
  const [retrieveError, setRetrieveError] = useState(null); // Holds any error message for retrieval
  const apiUrl = process.env.REACT_APP_API_BASE_URL;
  useEffect(() => {
    const handleClickOutside = (event) => {
      const modal = document.querySelector('.modal-content');
      if (modal && !modal.contains(event.target)) {
        setShowModal(false);
      }
    };

    if (showModal) {
      document.addEventListener('mousedown', handleClickOutside);
    } else {
      document.removeEventListener('mousedown', handleClickOutside);
    }

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [showModal]);

  const handleCheckStatus = async (e) => {
    e.preventDefault();
    setError(null); // Clear previous errors
    setStatus(null);
    setReason(null);

    if (!applicationId) {
      setError('Please enter your application id');
      return; // Exit early from the function
    }

    try {
      const response = await axios.get(`${apiUrl}/application/status/${applicationId}`);
      // Set the status and reason if found
      setStatus(response.data.status);
      if (response.data.reasonForRejection) {
        setReason(response.data.reasonForRejection);
      }
    } catch (err) {
      if (err.response && err.response.status === 404) {
        // If a 404 error is returned, set the specific not found error message
        setError(`Application with id ${applicationId} is not found`);
      } else {
        // For all other errors, set a generic error message
        setError('An error occurred while checking the status. Please try again later.');
      }
      console.error('Error checking status:', err);
    }
  };

  const handleRetrieveAppId = async (e) => {
    e.preventDefault();
    setRetrieveError(null);
    setRetrievedAppId(null);

    if (!aadhaarNumber) {
      setRetrieveError('Please enter your Aadhaar number');
      return;
    }

    try {
      const response = await axios.post(`${apiUrl}/application/retrieve`, { aadhaarNumber });
      setRetrievedAppId(response.data.applicationId);
    } catch (err) {
      setRetrieveError('An error occurred while retrieving the application number. Please try again later.');
      console.error('Error retrieving application number:', err);
    }
  };

  return (
    <div className="merged-pages">
      <div className="page-container">
        <Banner images={slides} />
      </div>
      <div className="page-container">
        <div className="status-form heading">
          <form onSubmit={handleCheckStatus}>
            <h2>Check Application Status</h2>
            <br />
            <label htmlFor="">Enter application number<span className="required">*</span></label>
            <input
              type="text"
              placeholder="Application number"
              value={applicationId}
              onChange={(e) => setApplicationId(e.target.value)}
            />
            <div className="forgot-link" onClick={() => setShowModal(true)}>Forgot Application Number?</div>
            <button className="button321" type="submit">Check Status</button>
            {error && <div className="error">{error}</div>}
            {status && (
              <div className="status-result">
                <h2>Status: {status}</h2>
                {reason && <p>Reason: {reason}</p>}
              </div>
            )}
          </form>

          {showModal && (
            <div className="modal">
              <div className="modal-content">
                <form onSubmit={handleRetrieveAppId}>
                  <span className="close" onClick={() => setShowModal(false)}>×</span>
                  <h2>Retrieve Application Number</h2>
                  <label htmlFor="aadhaarNumber">Enter your Aadhaar number</label>
                  <input
                    type="text"
                    id="aadhaarNumber"
                    placeholder="Aadhaar number"
                    value={aadhaarNumber}
                    onChange={(e) => setAadhaarNumber(e.target.value)}
                  />
                  <button type="submit">Retrieve</button>

                  {retrieveError && <div className="error">{retrieveError}</div>}
                  {retrievedAppId && (
                    <div className="retrieved-application-id">
                      <p>Your application number is: {retrievedAppId}</p>
                    </div>
                  )}
                </form>
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default Status;
