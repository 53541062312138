import React, { useEffect, useState } from 'react'
import "../styles/Banner.css"

function Banner({ images }) {

    const [current, setCurrent] = useState(0)

    const [autoplay, setAutoplay] = useState(true);
    let timeout = null

    useEffect(() => {
        timeout = autoplay && setTimeout(() => {
            slideRight()
        }, 2500);
    })

    const slideRight = () => {
        setCurrent(current === images.length - 1 ? 0 : current + 1)
    }
    const slideLeft = () => {
        setCurrent(current === 0 ? images.length - 1 : current - 1)
    }


    return (
        <div className="banner" onMouseEnter={() => {
            setAutoplay(false)
            clearTimeout(timeout)
        }}
            onMouseLeave={() => setAutoplay(true)}>
            <div className="banner-container">
                {images.map((image, index) => {
                    return (<div key={index}
                        className={index === current
                            ? "banner-cards banner-cards-active" : "banner-cards"}>
                        <img className="cards-image" src={image.image} alt="" />
                        <div className="card-overlay">
                            <h2 className="card-title">
                                {image.title}
                            </h2>
                        </div>
                    </div>);

                })}

                <div className="arrow-left" onClick={slideLeft}>&lsaquo;</div>
                <div className="arrow-right" onClick={slideRight}>&rsaquo;</div>
                <div className="pagination">
                    {
                        images.map((_, index) => {
                            return (
                                <div key={index}
                                    className={index === current
                                        ? "pagination-dot pagination-dot-active" : "pagination-dot"}
                                    onClick={() => setCurrent(index)}>
                                </div>
                            )
                        })
                    }
                </div>
            </div>
        </div>
    )
}

export default Banner