import React, { useState, useEffect } from 'react';
import Select from 'react-select';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import * as yup from 'yup';

import '../styles/form.css';

import axios from 'axios';

const schema = yup.object().shape({
    name: yup.string().required('Name is required').matches(/^[a-zA-Z]+$/, "Name should be letters and spaces"),

    mobileNumber: yup.string()
        .length(10, 'Mobile number should be 10 digits')
        .matches(/^[0-9]+$/, 'Mobile number should only contain digits')
        .required('Mobile number is required'),
    email: yup.string().email('Invalid email').required('Email is required'),
    dateOfBirth: yup.date().required('Date of birth is required'),
    age: yup.number().positive('Age must be a positive number').integer('Age must be an integer').required('Age is required'),
    gender: yup.string().required('Gender is required'),
    permanent_address: yup.string().max(200, 'Address should not be more than 200 characters').required('Please enter your permanent address'),
    current_address: yup.string().max(200, 'Address should not be more than 200 characters').required('Please enter your current address'),
    country: yup.string().required('Please select your country'),
    state: yup.string().required('Please select your state'),
    aadharNumber: yup.string()
        .length(12, 'Aadhar number should be 12 digits only')
        .matches(/^[0-9]+$/, 'Aadhar number should only contain digits')
        .required('Aadhar number is required'),
    aadhaarCopy: yup.mixed()
        .required('Aadhaar card is required')
        .test('fileSize', 'File size should be less than 1MB', value => {
            if (!value) return true; // If no file uploaded, skip the test
            return value.size <= 1048576; // File size should be less than or equal to 1MB (1048576 bytes)
        }),
    panNumber: yup.string()
        .length(10, 'PAN number should be 10 digits only')
        .matches(/^[0-9]+$/, 'PAN number should only contain digits')
        .required('PAN number is required'),
    panCopy: yup.mixed()
        .required('PAN card is required')
        .test('fileSize', 'File size should be less than 1MB', value => {
            if (!value) return true; // If no file uploaded, skip the test
            return value.size <= 1048576; // File size should be less than or equal to 1MB (1048576 bytes)
        }),

});


const mySchema = yup.object().shape({
    name: yup.string()
        .required('This field is required')
});

const PersonalDetails = ({ onNext }) => {
    // State to hold the form values
    const [formValues, setFormValues] = useState({});

    const [errors, setErrors] = useState({});

    // get previous data from the database
    let data;
    const getData = () => {
        try {
            const response = axios.get();
            data = response.data;
        }
        catch (error) {
            console.error('Error sending form data:', error);
        }
    }

    useEffect(() => {
        const fetchData = async () => {
            const user_id = localStorage.getItem("user_id")
            console.log(`userid from localstorage : ${user_id}`)
            const apiUrl = process.env.REACT_APP_API_BASE_URL;
            if (user_id) {
                let response
                try {
                    response = await axios.get(`${apiUrl}/application/getUserInfo/${user_id}`)
                } catch (e) {
                    console.log(`error fetching user details ${e}`)
                }
                const user = response.data
                if (user) {
                    console.log(user.basicDetail.name)
                    const dob = new Date(user.basicDetail.dob)
                    const formatDate = dob.toISOString().split('T')[0]
                    setFormData({
                        ...formData,
                        name: user.basicDetail.name,
                        aadharNumber: user.basicDetail.aadharno,
                        email: user.contactDetail.Email,
                        mobileNumber: user.contactDetail.MobileNumber,
                        gender: user.basicDetail.gender,
                        dateOfBirth: formatDate,
                        country: user.basicDetail.country,
                        state: user.basicDetail.state,
                        panNumber: user.basicDetail.pan
                    })
                    // setFormData({...formData, aadharNumber : user.basicDetail.aadharno  })
                    setPermanentAddress(user.contactDetail.permanent_address)
                    setCurrentAddress(user.contactDetail.address)
                }
            }
        }
        fetchData()

    }, [])

    // set initial values of formdata to the data from database
    //address related
    const [permanentAddress, setPermanentAddress] = useState('');
    const [currentAddress, setCurrentAddress] = useState('');
    const [isSameAsCurrent, setisSameAsCurrent] = useState(false);


    const handleIsSameAsCurrentChange = (e) => {
        setisSameAsCurrent(e.target.checked);
        if (e.target.checked) {
            setPermanentAddress(currentAddress);
        }
    };

    const handleCurrentAddressChange = (e) => {
        setCurrentAddress(e.target.value);
        const { name, value } = e.target;
        const error = validateInput(name, value)
        setErrors(prevErrors => ({ ...prevErrors, [name]: error }))
        if (isSameAsCurrent) {
            setPermanentAddress(e.target.value);
        }
    };

    const handlePermanentAddressChange = (e) => {
        setPermanentAddress(e.target.value);
        const { name, value } = e.target;
        const error = validateInput(name, value)
        setErrors(prevErrors => ({ ...prevErrors, [name]: error }))
        setFormData({ ...formData, [name]: value });
    };


    const [formData, setFormData] = useState({
        name: '',
        mobileNumber: '',
        email: '',
        dateOfBirth: '',
        age: '',
        gender: '',
        aadharNumber: '',
        country: '',
        state: '',
        permanent_address: '',
        current_address: '',
        aadhaarCopy: null,
        panNumber: '',
        panCopy: null
    });
    const countries = ['India'];

    const states = (country) => {
        switch (country) {
            case 'India':
                return ['Andhra Pradesh',
                    'Arunachal Pradesh',
                    'Assam',
                    'Bihar',
                    'Chhattisgarh',
                    'Goa',
                    'Gujarat',
                    'Haryana',
                    'Himachal Pradesh',
                    'Jharkhand',
                    'Karnataka',
                    'Kerala',
                    'Madhya Pradesh',
                    'Maharashtra',
                    'Manipur',
                    'Meghalaya',
                    'Mizoram',
                    'Nagaland',
                    'Odisha',
                    'Punjab',
                    'Rajasthan',
                    'Sikkim',
                    'Tamil Nadu',
                    'Telangana',
                    'Tripura',
                    'Uttar Pradesh',
                    'Uttarakhand',
                    'West Bengal',
                    'Andaman and Nicobar Islands',
                    'Chandigarh',
                    'Dadra and Nagar Haveli and Daman and Diu',
                    'Lakshadweep',
                    'Delhi',
                    'Puducherry',
                    'Ladakh',
                    'Jammu and Kashmir'
                    ];
            default:
                return [];
        }
    }

    const handleNext = async (e, { setSubmitting }) => {
        setSubmitting(false);
    };
    const validInputs = () => {
        console.log(`these are the errors : ${JSON.stringify(errors)}`)
        for (let key in errors) {
            console.log(`these are the keys : ${key} and values ${errors[key]}`)
            if (errors[key] != null) {
                return false
            }
        }
        return true
    }


    const validateInput = (name, value) => {
        console.log(`${name} and ${value}`)

        function containsNumbersOrSpecialChars(str) {
            // Regular expression to match numbers or special characters
            const specialChars = /[0-9!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]+/;
            return specialChars.test(str);
        }
        function isValidEmail(email) {
            const regex = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
            return regex.test(email);
        }
        function isValidMobileNumber(mobileNumber) {
            const regex = /^[0-9]{10}$/;
            return regex.test(mobileNumber);
        }
        function isValidAadharNumber(aadhar) {
            const regex = /^[0-9]{12}$/;
            return regex.test(aadhar);
        }
        function isValidPAN(panNumber) {
            const regex = /^[A-Z]{5}[0-9]{4}[A-Z]$/;
            return regex.test(panNumber);
        }
        switch (name) {
            case 'name':
                if (value === '') {
                    return 'name is required'
                } if (value.length < 3) {
                    return 'name should be atleast 3 letters'
                } if (value.length > 50) {
                    return 'name should have less than 50 characters'
                } if (containsNumbersOrSpecialChars(value)) {
                    return 'please make sure no numbers or specal characters are entered'
                }
                break
            case 'email':
                if (value === '') {
                    return 'email is required'
                }
                if (!isValidEmail(value)) {
                    return 'enter valid email'
                }
                break
            case 'mobileNumber':
                if (value === '') {
                    return 'mobile number is required'
                } if (!isValidMobileNumber(value)) {
                    return 'enter 10 digit mobile number'
                }
                break
            case 'dateOfBirth':
                if (value === '') {
                    return 'dob is required'
                }
                break
            case 'gender':
                if (value === '') {
                    return 'Gender is Required'
                }
                break
            case 'aadharNumber':
                if (value === '') {
                    return 'aadhar is required'
                }
                if (!isValidAadharNumber(value)) {
                    return 'enter valid aadhar'
                }
                break
            case 'panNumber':
                if (value === '') {
                    return 'pan is required'
                } if (!isValidPAN(value)) {
                    return 'enter valid pan number'
                }
                break
            case 'current_address':
                if (value === '') {
                    return 'current address is required'
                } if (value.length < 30) {
                    return 'name should be atleast 30 letters'
                }
                break
            case 'permanent_address':
                if (value === '') {
                    return 'permanent address is required'
                }if (value.length < 30) {
                    return 'name should be atleast 30 letters'
                }
        }
    }

    const handleChange = (e, setFieldValue) => {
        const { name, value, files } = e.target;
        if (name === 'aadhaarCopy' || name === 'panCopy') {
            const file = files[0];
            if (file && file.size > 1048576) {
                alert('File size should be less than 1MB');
                // Optionally you can clear the file input value
                e.target.value = '';
                setFieldValue(name, null); // Clear the file input value
                return;
            } else {
                // Set file in formData directly
                setFormData({ ...formData, [name]: file });
                // Set file using Formik setFieldValue
                setFieldValue(name, file);
            }
        } else {
            setFormData({ ...formData, [name]: value });
        }
        const error = validateInput(name, value)
        setErrors(prevErrors => ({ ...prevErrors, [name]: error }))
    };

    //API call
    // Function to save the form data
    const saveData = async (event) => {
        event.preventDefault();
        const apiUrl = process.env.REACT_APP_API_BASE_URL;
        //send personal details to the backend
        console.log(`this is the permanent address ${formData.permanent_address} and thiss is the current address : ${formData.current_address}`)
        try {
            const personalData = {
                permanent_address: permanentAddress,
                current_address: currentAddress,
                aadhar_number: formData.aadharNumber,
                gender: formData.gender,
                date_of_birth: formData.dateOfBirth,
                mobile_number: formData.mobileNumber,
                email_address: formData.email,
                full_name: formData.name,
                country: formData.country,
                state: formData.state,
                pan: formData.panNumber
            }

            const response = await axios.post(apiUrl+'/application/uploadPersonalDetails', personalData)
            const user_id = response.data.user_id
            console.log(user_id)
            localStorage.setItem("user_id", user_id)

        } catch (e) {
            console.log(`this is the error : \n ${e}`)
        }
        //send document to the backend
        try {
            const data = new FormData();
            const user_id = localStorage.getItem("user_id")
            data.append('userid', user_id); // change it to the userid after creation of the user
            data.append('aadhar', formData.aadhaarCopy);
            if (formData.panCopy !== null) {
                data.append('pan', formData.panCopy);
            }


            const response = await axios.post(apiUrl+'/uploadFiles', data);
            console.log('Form data sent successfully:', response.data);

        } catch (error) {
            console.error('Error sending form data:', error);
        }
    };


    return (
        <Formik

            initialValues={{
                name: '',
                mobileNumber: '',
                email: '',
                dateOfBirth: '',
                age: '',
                gender: '',
                aadharNumber: '',
                country: 'India',
                state: 'Karnataka',
                current_address: '',
                permanent_address: '',
                aadhaarCopy: null, // Initialize Aadhaar copy field as null
                panNumber: '',
                panCopy: null
            }}
            // validationSchema={schema}
            onSubmit={handleNext}
        >

            {({ isSubmitting, setFieldValue }) => (
                // <Form className="form">
                <div className='heading'>
                    <Form>
                        <h2>Scholarship Application</h2>
                        <div className='progress' style={{ position: "relative" }}>
                            <ul>
                                <li><div id='step1'>Personal Details</div></li>
                                <li style={{ backgroundColor: "white", paddingRight: "10px" }}><div id='step2' >Academic Details</div></li>
                                <li style={{ backgroundColor: "white" }}><div id='step3'>Family Details</div></li>
                                <li style={{ backgroundColor: "white" }}><div id='step4'>Bank Details</div></li>

                                <div className="progressdiv">
                                    <progress value={0.25} color='blue' />
                                </div>

                            </ul>
                        </div>
                        <div className="form">
                            <div className="field"><label htmlFor="name">Full Name<span className="required">*</span></label>
                                <Field className="field12" name="name" type="text" onChange={handleChange} value={formData.name}
                                    placeholder="Name"></Field>
                                {/* <ErrorMessage className='error' name="name" component="div" /> */}
                                {errors.name && <div className="error">{errors.name}</div>}
                            </div>

                            <div className="field">
                                <label htmlFor="email">Email address<span className="required">*</span></label>
                                <Field className="field12" name="email" type="email" required onChange={handleChange} value={formData.email}
                                    placeholder="Email"></Field>
                                {/* <ErrorMessage className='error' name="email" component="div" /> */}
                                {errors.email && <div className="error">{errors.email}</div>}

                            </div>
                            <div className="field">
    <label htmlFor="mobileNumber">Mobile Number<span className="required">*</span></label>
    <Field
        className="field12"
        name="mobileNumber"
        type="text"
        required
        onChange={(e) => {
            let { value } = e.target;
            // Remove non-numeric characters from the input value
            value = value.replace(/\D/g, '');
            // Limit input to 10 digits
            value = value.slice(0, 10);
            // Update the form field value
            setFormData({ ...formData, mobileNumber: value });
            const error = validateInput('mobileNumber', value);
            setErrors(prevErrors => ({ ...prevErrors, mobileNumber: error }));
        }}
        value={formData.mobileNumber}
        placeholder="Mobile Number"
    />
    {errors.mobileNumber && <div className="error">{errors.mobileNumber}</div>}
</div>

                            <div className="field">
                                <label htmlFor="dateOfBirth">DOB<span className="required">*</span></label>
                                <Field
                                    className="field12"
                                    name="dateOfBirth"
                                    required
                                    type="date"
                                    onChange={handleChange}
                                    value={formData.dateOfBirth}
                                    placeholder="DD-MM-YYYY"
                                />
                                {errors.dateOfBirth && <div className="error">{errors.dateOfBirth}</div>}
                            </div>


                            <div className="field">
                                <label htmlFor="gender">Gender<span className="required">*</span></label>
                                <Field className="field12" as="select" name="gender" required onChange={handleChange} value={formData.gender}>
                                    <option value="">Select</option>
                                    <option value="male">Male</option>
                                    <option value="female">Female</option>
                                    <option value="other">Other</option>
                                </Field>
                                {/* <ErrorMessage className='error' name="gender" component="div" /> */}

                                {errors.gender && <div className="error">{errors.gender}</div>}

                            </div>

                            <div className="field">
                                <label htmlFor="aadharNumber">Aadhar Number<span className="required">*</span></label>
                                <Field
                                    className="field12"
                                    name="aadharNumber"
                                    type="text"
                                    required
                                    // onChange={handleChange}
                                    onChange={(e) => {
                                        let { value } = e.target;
                                        // Remove non-numeric characters from the input value
                                        value = value.replace(/\D/g, '');
                                        // Limit input to 12 digits
                                        value = value.slice(0, 12);
                                        // Insert "-" after every fourth character
                                        const formattedPAadharNumber = value.replace(/(\d{4})(\d{4})(\d{4})/, '$1-$2-$3');
                                        // Update the form field value
                                        setFormData({ ...formData, aadharNumber: formattedPAadharNumber });
                                    }}
                                    value={formData.aadharNumber}
                                    placeholder="0000-0000-0000">
                                </Field>
                                {/* <ErrorMessage className='error' name="aadharNumber" component="div" /> */}
                                {errors.aadharNumber && <div className="error">{errors.aadharNumber}</div>}

                            </div>

                            <div className="field">
                                <label htmlFor="country">Country<span className="required">*</span></label>
                                <Select
                                    name="country" required
                                    options={countries.map(country => ({ value: country, label: country }))}
                                    onChange={(selectedOption) => {
                                        const selectedCountry = selectedOption.value;
                                        const error = validateInput('country', selectedCountry)
                                        setErrors(prevErrors => ({ ...prevErrors, 'country': error }))
                                        setFormData({ ...formData, country: selectedCountry });
                                        setFieldValue('country', selectedCountry);
                                        setFieldValue('state', ''); // Reset state when country changes
                                    }}
                                    placeholder="India"
                                    styles={{
                                        // container: (provided) => ({
                                        //   ...provided,
                                        //   width: '100%',
                                        // }),
                                        control: (provided, state) => ({
                                            ...provided,
                                            height: '4rem',
                                            borderRadius: '5px',
                                            border: state.isFocused ? '1px solid #007bff' : '1px solid #c4d1eb',
                                            marginTop: '1rem',
                                            marginBottom: '1rem',
                                            //   width:'100%',
                                            //   maxWidth: '500px',

                                        }),
                                        input: (provided) => ({
                                            ...provided,
                                            margin: '0',
                                            padding: '0px',
                                            alignItems: 'center',
                                            maxWidth: '300px',
                                            height: '3.5rem',
                                        }),
                                    }}
                                />
                                {/* <ErrorMessage className='error' name="country" component="div" /> */}
                                {errors.country && <div className="error">{errors.country}</div>}

                            </div>

                            <div className="field">
                                <label htmlFor="state">State<span className="required">*</span></label>
                                <Select
                                    name="state" required
                                    options={states(formData.country).map(state => ({ value: state, label: state }))}
                                    onChange={(selectedOption) => {
                                        const selectedState = selectedOption.value;
                                        setFormData({ ...formData, state: selectedState });
                                        setFieldValue('state', selectedState);
                                    }}
                                    placeholder="Karnataka"
                                    styles={{
                                        control: (provided, state) => ({
                                            ...provided,
                                            height: '4rem',
                                            borderRadius: '5px',
                                            border: state.isFocused ? '1px solid #007bff' : '1px solid #c4d1eb',
                                            marginTop: '1rem',
                                            marginBottom: '1rem',
                                        }),
                                        input: (provided) => ({
                                            ...provided,
                                            margin: '0',
                                            padding: '0px',
                                            alignItems: 'center',
                                            maxWidth: '300px',
                                            height: '3.5rem',
                                        }),
                                    }}
                                />
                                <ErrorMessage className='error' name="state" component="div" />
                            </div>

                        </div>

                        <div>
                            <label htmlFor="current_address">Current Address<span className="required">*</span></label>
                            <textarea className="field12" name="current_address" type="text" id="currentAddress"
                                value={currentAddress} required
                                onChange={handleCurrentAddressChange}
                                placeholder="Enter your current address"></textarea>

                            {/* <ErrorMessage className='error' name="current_address" component="div" /> */}
                            {errors.current_address && <div className="error">{errors.current_address}</div>}

                        </div>

                        <div>
                            <div className="address">
                                <label className='address_per' htmlFor="permanent_address">Permanent Address<span className="required">*</span></label>
                                <div className="address_checkbox">
                                    <label htmlFor="sameAsCurrent" className="sameascurrentaddress">Same as Current Address? </label>
                                    <input
                                        className="sameascurrentaddresschk"
                                        type="checkbox"
                                        id="sameAsCurrent"
                                        required
                                        checked={isSameAsCurrent}
                                        onChange={handleIsSameAsCurrentChange}
                                    />
                                </div>
                            </div>

                            <textarea className="field12" name="permanent_address" type="text"
                                id="permanentAddress"
                                value={permanentAddress}
                                required
                                placeholder="Enter your permanent address"
                                onChange={handlePermanentAddressChange}
                                disabled={isSameAsCurrent}
                            ></textarea>

                            {/* <ErrorMessage className='error' name="permanent_address" component="div" /> */}
                            {errors.permanent_address && <div className="error">{errors.permanent_address}</div>}

                        </div>

                        <br></br>
                        <div className="field" >
                            <div style={{ display: 'flex', alignItems: 'center' }}>
                                <label htmlFor="aadhaarCopy">Upload Aadhaar Card<span className="required">*</span>
                                </label>

                                <div>
                                    <label htmlFor="" className="redLabel2">(only in pdf, png, jpg and jpeg format and max size should be 1MB)</label>
                                </div>
                            </div>

                            <input
                                type="file"
                                id="aadhaarCopy"
                                className='aadhaarcardFile'
                                name="aadhaarCopy"
                                accept='.pdf,.png,.jpg,.jpeg'
                                required
                                onChange={(event) => handleChange(event, setFieldValue)}
                            />
                            <ErrorMessage className='error' name="aadhaarCopy" component="div" />
                        </div>

                        {/* PAN Card Details of Student */}
                        <div className="field">
                            <label htmlFor="panNumber">PAN Number</label>
                            <Field className="field12" name="panNumber" type="text" required onChange={handleChange} value={formData.panNumber}
                                placeholder="PAN Number"></Field>
                            {/* <ErrorMessage className='error' name="panNumber" component="div" /> */}
                            {errors.panNumber && <div className="error">{errors.panNumber}</div>}

                        </div>

                        <div className="field" >
                            <div style={{ display: 'flex', alignItems: 'center' }}>
                                <label htmlFor="panCopy">Upload PAN Card
                                </label>

                                <div>
                                    <label htmlFor="" className="redLabel2">(only in pdf, png, jpg and jpeg format and max size should be 1MB)</label>
                                </div>
                            </div>

                            <input
                                type="file"
                                id="panCopy"
                                name="panCopy"
                                className='pancardFile'
                                accept='.pdf,.png,.jpg,.jpeg'
                                required
                                onChange={(event) => handleChange(event, setFieldValue)}
                            />
                            <ErrorMessage className='error' name="panCopy" component="div" />
                        </div>

                        <button id='btn'
                            onClick={(event) => {
                                saveData(event);
                                if (validInputs()) {
                                    onNext();
                                }
                            }}
                            type="submit"
                            disabled={isSubmitting}
                        >
                            Next
                        </button>


                    </Form>

                </div>
            )}

        </Formik>
    );
};

export default PersonalDetails;
