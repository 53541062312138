import React, { useState } from 'react';
import '../styles/FamilyForm2.css';
import * as Yup from 'yup';
import axios from 'axios';

function FamilyForm({ onPrevious, onNext }) {
    const [formData, setformData] = useState([
        {
            name: "",
            label: "",
            age: "",
            qualification: "",
            designation: "",
            nameoforg: "",
            annualincome: "",
            bankStatement: null,
            panCard: "",
            aadhaarNumber: "",
            panCopy: null,
            aadhaarCopy: null,
            rentalAgreement: null,
            isRental: false,
            isOwnHouse: false
        }
    ]);

    const [isChecked, setIsChecked] = useState(false);
    const [isCheckedParent, setIsCheckedParent] = useState(false);
    const [dropdownValue, setDropdownValue] = useState('');
    const [errors, setErrors] = useState({});
    const apiUrl = process.env.REACT_APP_API_BASE_URL;

    const toggleCheckbox = () => {
        setIsChecked(!isChecked);
    };

    const toggleCheckboxParent = () => {
        setIsCheckedParent(!isCheckedParent);
        setDropdownValue(''); // Reset dropdown value when checkbox is toggled
    };

    const handleDropdownChange = (event) => {
        setDropdownValue(event.target.value);
    };

    const handleRentalSelection = () => {
        const newformData = [...formData];
        newformData[0].isRental = !formData[0].isRental;
        newformData[0].isOwnHouse = false;
        setformData(newformData);
    };

    const handleOwnHouseSelection = () => {
        const newformData = [...formData];
        newformData[0].isOwnHouse = !formData[0].isOwnHouse;
        newformData[0].isRental = false;
        setformData(newformData);
    };

    const validInputs = () => {
        console.log(`these are the errors : ${JSON.stringify(errors)}`);
        let valid = true;
        Object.keys(errors).forEach(key => {
            if (errors[key] != null && errors[key] !== '') {
                valid = false;
            }
        });
        return valid;
    };

    const handleFormChange = (e, i) => {
        const field = e.target.name;
        const newformData = [...formData];
        if (field === 'bankStatement' || field === 'panCopy' || field === 'deathCertificate' || field === 'aadhaarCopy' || field === 'rentalAgreement') {
            const fileSize = e.target.files[0].size;
            const allowedFormats = ['application/pdf', 'image/jpg', 'image/png', 'image/jpeg'];
            const allowedSize = 1048576;
            if (!allowedFormats.includes(e.target.files[0].type)) {
                setErrors(prevErrors => ({ ...prevErrors, [field]: 'Invalid file format. Allowed formats: PDF, PNG, JPG, JPEG' }));
                return;
            } else if (fileSize > allowedSize) {
                alert("File size should be less than 1MB.");
                e.target.value = null;
                return;
            }
        }
        if (field === 'aadhaarNumber') {
            const inputValue = e.target.value.replace(/\D/g, '').slice(0, 12);
            const formattedAadhaar = inputValue.replace(/(\d{4})(\d{4})(\d{4})/, '$1-$2-$3');
            newformData[i][field] = formattedAadhaar;
        } else {
            newformData[i][field] = e.target.files ? e.target.files[0] : e.target.value;
        }

        setformData(newformData);
        schema.validateAt(field, newformData[i])
            .then(() => {
                setErrors(prevErrors => ({ ...prevErrors, [field]: '' }));
            })
            .catch(error => {
                setErrors(prevErrors => ({ ...prevErrors, [field]: error.message }));
            });
    };

    const handleAddForm = () => {
        setformData([...formData, {
            name: "",
            label: "",
            age: "",
            qualification: "",
            designation: "",
            nameoforg: "",
            annualincome: "",
            bankStatement: null,
            panCard: "",
            aadhaarNumber: "",
            panCopy: null,
            aadhaarCopy: null,
            rentalAgreement: null,
            isRental: false,
            isOwnHouse: false
        }]);
    };

    const handleDeleteForm = (i) => {
        if (formData.length === 1) {
            return;
        }
        const newformData = [...formData];
        newformData.splice(i, 1);
        setformData(newformData);
    };

    const schema = Yup.object().shape({
        label: Yup.string().required('Family member type is required'),
        name: Yup.string().required('Name is required').matches(/^[a-zA-Z]+$/, "Name should be letters and spaces"),
        age: Yup.number().positive('Age must be a positive number').integer('Age must be an integer').required('Age is required'),
        qualification: Yup.string().required('Qualification is required'),
        designation: isChecked ? Yup.string().required('Designation is required') : Yup.string(),
        nameoforg: isChecked ? Yup.string().required('Organization name is required') : Yup.string(),
        annualincome: Yup.string().required('Annual income is required').matches(/^\d+$/, "Annual Income should only contain number"),
        bankStatement: Yup.mixed().required("Bank Statement is required"),
        panCard: Yup.string().required('PAN Card number is required').matches(/^([a-zA-Z]){5}([0-9]){4}([a-zA-Z]){1}?$/, "Invalid PAN Card number"),
        aadhaarNumber: Yup.string().required('Aadhaar Card number is required').matches(/^\d{4}-\d{4}-\d{4}$/, "Invalid Aadhaar number"),
        panCopy: Yup.mixed().required("PAN Card copy is required"),
        aadhaarCopy: Yup.mixed().required("Aadhaar Card copy is required"),
        rentalAgreement: Yup.mixed().required("Rental agreement is required"),
        deathCertificate: Yup.mixed().required("Death Certificate is required"),
    });

    const handleSubmit = async (event) => {
        event.preventDefault();
        try {
            await schema.validate(formData, { abortEarly: false });
            console.log(formData);
            setformData([]);
            setErrors({});
        } catch (validationErrors) {
            const formattedErrors = {};
            validationErrors.inner.forEach(error => {
                formattedErrors[error.path] = error.message;
            });
            setErrors(formattedErrors);
        }
    };

    const saveData = async () => {
        const user_id = localStorage.getItem("user_id");
        let familyData = [];
        formData.forEach(member => {
            const relation = member.label;
            const name = member.name;
            const age = member.age;
            const qualification = member.qualification;
            const designation = member.designation;
            const nameoforg = member.nameoforg;
            const annualincome = member.annualincome;
            const pan_number = member.panCard;
            const aadhar = member.aadhaarNumber;

            const memberData = {
                relation: relation,
                name: name,
                age: age,
                qualification: qualification,
                designation: designation,
                nameoforg: nameoforg,
                annualincome: annualincome,
                pan: pan_number,
                aadhar: aadhar
            };
            familyData.push(memberData);
        });

        try {
            console.log(`this is the family data\n`, familyData);
            const response = await axios.post(apiUrl+'/application/uploadFamilyDetails', {
                familyData: familyData,
                user_id: user_id
            });
        } catch (error) {
            console.log(error.message);
        }

        const data = new FormData();
        data.append('userid', user_id); // change it to correct userid

        formData.forEach(member => {
            if (member.bankStatement !== null) {
                data.append([member.label + "_bank_statement"], member.bankStatement);
            }
            if (member.panCopy !== null) {
                data.append([member.label + "_pan"], member.panCopy);
            }
            if (member.rentalAgreement !== null && member.isRental) {
                data.append([member.label + "_rental_agreement"], member.rentalAgreement);
            }
            if (member.aadhaarCopy !== null && member.aadhaarCopy) {
                data.append([member.label + "_aadhar"], member.aadhaarCopy);
            }
        });

        try {
            const response = await axios.post(apiUrl+'/uploadFiles', data);
            console.log(response.data);
        } catch (error) {
            console.log(error.message);
        }
    };

    return (
        <div className="heading">
            <form onSubmit={handleSubmit} >
                <h2>Scholarship Application</h2>
                <div className='progress' style={{ position: "relative" }}>
                    <ul>
                        <li><div id='step1'>Personal Details</div></li>
                        <li><div id='step2' >Academic Details</div></li>
                        <li><div id='step3'>Family Details</div></li>
                        <li style={{ backgroundColor: "white" }}><div id='step4'>Bank Details</div></li>
                    </ul>
                    <div className="progressdiv">
                        <progress value={0.75} color='blue' />
                    </div>
                </div>
                {formData.map((Form, index) => (
                    // <div key={index} className='Inputs-feilds-container'>
                    <div key={index} >
                         <div className="parent-status-form">
      <div className="checkbox">
        <label htmlFor="singleParent">Single Parent?</label>
        <input
          type="checkbox"
          name="singleParent"
          id="singleParent"
          checked={isCheckedParent}
          onChange={toggleCheckboxParent}
          disabled={index > 0}
        />
      </div>

      {isCheckedParent && (
        <div className="dropdown">
          <label htmlFor="parentStatus">Parent Status:</label>
          <select
            name="parentStatus"
            id="parentStatus"
            value={dropdownValue}
            onChange={handleDropdownChange}
          >
            <option value="">Select</option>
            <option value="left">Divorced</option>
            <option value="dead">Widowed</option>
          </select>
        </div>
      )}

      {dropdownValue === 'dead' && (
        <div className="death-certificate">
          <label htmlFor="deathCertificate">Death Certificate:</label>
          <input
            type="file"
            name="deathCertificate"
            id="deathCertificate"
            accept="application/pdf, image/*"
            required
          />
             {errors[`deathCertificate`] && <div className="error">{errors[`deathCertificate`]}</div>}
                            
        </div>
      )}
    </div>
                        <div className='label-feild'>
                            <select
                                value={Form.label}
                                name="label"
                                onChange={(e) => handleFormChange(e, index)}
                                required
                            >
                                <option value="">Select Family Member</option>
                                {isCheckedParent && index === 0 ? (
                                    <>
                                        <option value="Father">Father</option>
                                        <option value="Mother">Mother</option>
                                        <option value="Spouse">Spouse</option>
                                    </>
                                ) : isCheckedParent && index > 0 ? (
                                    <>
                                        <option value="Brother">Brother</option>
                                        <option value="Sister">Sister</option>
                                        <option value="Spouse">Spouse</option>
                                    </>
                                ) : !isCheckedParent && index > 1 ? (
                                    <>
                                        <option value="Brother">Brother</option>
                                        <option value="Sister">Sister</option>
                                        <option value="Spouse">Spouse</option>
                                    </>
                                ) : (
                                    <>
                                        <option value="Father">Father</option>
                                        <option value="Mother">Mother</option>
                                        <option value="Spouse">Spouse</option>
                                    </>
                                )}
                            </select>
                            {errors.label && <div className="error">{errors.label}</div>}

                        </div>

                        {/* Working Status */}
                        <div className="checkbox">
                            <label htmlFor="working"> Presently working?<span className="required">*</span></label>
                            <input type="checkbox"
                                name='working'
                                id='working'
                                checked={isChecked}
                                onChange={toggleCheckbox} />
                        </div>


                        {isChecked && (

                            <div className='hidden-feilds'>
                                <div className="field">
                                    <label htmlFor="designation">Designation <span className="required">*</span></label>
                                    <input
                                        type="text"
                                        placeholder="Designation"
                                        name="designation"
                                        id='designation'
                                        value={Form.designation}
                                        onChange={(e) => handleFormChange(e, index)}
                                        required
                                    />

                                    {errors.designation && <div className="error">{errors.designation}</div>}
                                </div>

                                <div className="field">
                                    <label htmlFor="nameoforg">Name of the Organization <span className="required">*</span></label>
                                    <input
                                        type="text"
                                        placeholder="Name of The Organization"
                                        name="nameoforg"
                                        id='nameoforg'
                                        value={Form.nameoforg}
                                        onChange={(e) => handleFormChange(e, index)}
                                        required
                                    />
                                    {errors.nameoforg && <div className="error">{errors.nameoforg}</div>}
                                </div>
                            </div>
                        )}

                        <div className="inputs-name">
                            <div className="field">
                                <label htmlFor="name">Name <span className="required">*</span></label>
                                <input
                                    type="text"
                                    placeholder="Name"
                                    name="name"
                                    id='name'
                                    value={Form.name}
                                    onChange={(e) => handleFormChange(e, index)}
                                    required
                                />

                                {errors.name && <div className="error">{errors.name}</div>}

                            </div>

                            <div className="field">
                                <label htmlFor="age">Age <span className="required">*</span></label>
                                <input
                                    type="text"
                                    placeholder="Age"
                                    name="age"
                                    id='age'
                                    value={Form.age}
                                    onChange={(e) => handleFormChange(e, index)}
                                    required
                                />
                                {errors.age && <div className="error">{errors.age}</div>}
                            </div>

                        </div>
                        <div className="qual-annual">
                            <div className="field">
                                <label htmlFor="qualification">Qualification<span className="required">*</span></label>
                                <input
                                    type="text"
                                    placeholder="Qualification"
                                    name="qualification"
                                    id='qualification'
                                    value={Form.qualification}
                                    onChange={(e) => handleFormChange(e, index)}
                                    required
                                />
                                {errors.qualification && <div className="error">{errors.qualification}</div>}

                            </div>

                            <div className="field">
                                <label htmlFor="annualincome">Annual Income INR <span className="required">*</span></label>
                                <input
                                    type="text"
                                    placeholder="Annual Income"
                                    name="annualincome"
                                    id='annualincome'
                                    value={Form.annualincome}
                                    onChange={(e) => handleFormChange(e, index)}
                                    required
                                />
                                {errors.annualincome && <div className="error">{errors.annualincome}</div>}
                            </div>

                        </div>
                        <div className="field">
                            <label htmlFor="panCard">PAN Card Number <span className="required">*</span></label>
                            <input
                                type="text"
                                placeholder="PAN Card Number"
                                name="panCard"
                                id='panCard'
                                value={Form.panCard}
                                onChange={(e) => handleFormChange(e, index)}
                                required
                            />
                            {errors.panCard && <div className="error">{errors.panCard}</div>}
                        </div>

                        <div style={{ display: 'flex', alignItems: 'center' }}>
                            <label htmlFor="panCopy">Upload Pan Card <span className="required">*</span></label>
                            <div>
                                <label htmlFor="" className="redLabel23">(only in pdf, png, jpg and jpeg format and max size should be 1MB)</label>
                            </div>
                        </div>
                        <input
                            type="file"
                            className='family_files'
                            name="panCopy"
                            id='panCopy'
                            onChange={(e) => handleFormChange(e, index)}
                            accept=".pdf,.doc,.docx"
                            required

                        />
                        {errors[`panCopy`] && <div className="error">{errors[`panCopy`]}</div>}

                        <div className="field">
                            <label htmlFor="aadhaarNumber">Aadhaar Card Number <span className="required">*</span></label>
                            <input
                                type="text"
                                placeholder="Aadhaar Number"
                                name="aadhaarNumber"
                                id='aadhaarNumber'
                                value={Form.aadhaarNumber}
                                onChange={(e) => handleFormChange(e, index)}
                                required
                            />
                            {errors.aadhaarNumber && <div className="error">{errors.aadhaarNumber}</div>}
                        </div>



                        <div style={{ display: 'flex', alignItems: 'center' }}>
                            <label htmlFor="aadhaarCopy">Upload Aadhaar Card <span className="required">*</span></label>
                            <div>
                                <label htmlFor="" className="redLabel23">(only in pdf, png, jpg and jpeg format and max size should be 1MB)</label>
                            </div>
                        </div>
                        <input
                            type="file"
                            className='family_files'
                            name="aadhaarCopy"
                            id='aadhaarCopy' // Add unique id for each file input
                            onChange={(e) => handleFormChange(e, index)}
                            accept=".pdf,.png,.jpg,.jpeg"
                            required
                        />
                        {errors[`aadhaarCopy`] && <div className="error">{errors[`aadhaarCopy`]}</div>}

                        {/* File upload for rental agreement */}
                        <label >House Status :<span className="required">*</span></label>
                        <div className='checkbox' style={{ marginLeft: '150px', marginTop: '-35px' }}>
                            <label htmlFor="isRental">Rented</label>
                            <input
                                type="checkbox"
                                name="isRental"
                                checked={Form.isRental}
                                onChange={() => handleRentalSelection()}
                            />
                        </div>
                        {/* File upload for own house agreement */}
                        <div className='checkbox' style={{ marginTop: '-60px', marginLeft: '300px' }}>
                            <label htmlFor="isOwnHouse">Own House</label>
                            <input
                                type="checkbox"
                                name="isOwnHouse"
                                checked={Form.isOwnHouse}
                                onChange={() => handleOwnHouseSelection()}
                            />
                        </div>
                        {/* Rental Agreement Upload */}
                        {Form.isRental && (
                            <div>
                                <div style={{ display: 'flex', alignItems: 'center' }}>
                                    <label htmlFor="rentalAgreement">Upload Rental Agreement</label>
                                    <div>
                                        <label htmlFor="" className="redLabel23">(only in pdf, png, jpg and jpeg format and max size should be 1MB)</label>
                                    </div>
                                </div>
                                <input
                                    type="file"
                                    className='family_files'
                                    name="rentalAgreement"
                                    id="rentalAgreement"
                                    onChange={(e) => handleFormChange(e, index)}
                                    accept=".pdf,.png,.jpg,.jpeg"
                                    required
                                />
                                {errors[`rentalAgreement`] && <div className="error">{errors[`rentalAgreement`]}</div>}
                            </div>
                        )}


                        <br />
                        <div style={{ display: 'flex', alignItems: 'center' }}>
                            <label htmlFor="file">Please upload last 1 year Bank statement<span className="required">*</span></label>
                            <div>
                                <label htmlFor="" className="redLabel2">(only in pdf, png, jpg and jpeg format and max size should be 1MB)</label>
                            </div>
                        </div>

                        <p className='required'>Note: Government Provided Income Certificate is not accepted</p>
                        <input
                            type="file"
                            className='family_files'
                            name="bankStatement"
                            id='bankStatement'
                            onChange={(e) => handleFormChange(e, index)}
                            accept=".pdf,.doc,.docx"
                            required

                        />
                        {errors[`bankStatement`] && <div className="error">{errors[`bankStatement`]}</div>}


                        <button onClick={() => handleDeleteForm(index)} disabled={index === 0} className='delete'>Delete</button>
                    </div>
                ))}
                <br />
                
                {/* <button onClick={handleAddForm}>Add Family Member</button> */}
                <input type="button" value="Add Family Member" onClick={handleAddForm} />

                <div className="button-container">
                    <button id='btn' onClick={() => {
                        saveData();
                        onPrevious();
                    }} type="submit2">Previous</button>
                    <button id='btn' onClick={() => {
                        saveData();
                        if (validInputs()) {
                            onNext();
                        }

                    }} type="submit3">Next</button>

                </div>

            </form>

        </div>
    );


}
export default FamilyForm
