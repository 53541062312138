import React from 'react';
import '../styles/submitApplication.css';

function submitApplication() {
  const userId = localStorage.getItem("user_id");

  return (
    <div className="thankyou-container">
      <h2 className="thankyou-text">Thank you for applying to this scholarship.</h2>

      <p className="note-text">Please make a note of your UserID <span className="user-id">{userId}</span> to check the status of your application.
      <br></br>
        You can check your status <a href="/status">here</a>.
      </p>
    </div>
  );
}


export default submitApplication