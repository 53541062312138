import React, { useState } from 'react';
import '../styles/form.css';
import axios from 'axios';

function MyForm({ onNext, onPrevious }) {
    const [education, setEducation] = useState([]);
    const [educationCounter, setEducationCounter] = useState(1);
    const [fileUploads, setFileUploads] = useState([]);

    const [has10thEducation, setHas10thEducation] = useState(false);
    const [has12thEducation, setHas12thEducation] = useState(false);
    const apiUrl = process.env.REACT_APP_API_BASE_URL;
    const addEducation = () => {
        setEducation([...education, {
            id: educationCounter,
            degree: '',
            institution: '',
            currentlyStudying: false,
            startDate: '',
            graduationDate: '',
            program: '',
            fieldOfStudy: '',
            cgpa: '',
            file: null,
            fileUploads: [],
        }]);
        setEducationCounter(educationCounter + 1);
        setFileUploads([...fileUploads, []]);
    };

    const handleChange = (index, event) => {
        const values = [...education];

        if (event.target.type === 'file') {
            const file = event.target.files[0];
            if (file && file.size > 1024 * 1024) {
                alert("File size should be less than 1MB.");
                event.target.value = null;
                return;
            }
            values[index].file = file;
        } else {
            values[index][event.target.name] = event.target.value;
            if (event.target.name === 'degree' && event.target.value === 'Graduation') {
                if (index === 0) {
                    setHas10thEducation(values[0].degree === '10th' && values[0].institution !== '');
                    setHas12thEducation(values[0].degree === '12th' && values[0].institution !== '');
                }
            } else if (event.target.name === 'degree' && (event.target.value === '10th' || event.target.value === '12th')) {
                if (index === 0 && event.target.value === '10th') {
                    setHas10thEducation(true);
                } else if (index === 0 && event.target.value === '12th') {
                    setHas12thEducation(true);
                }
            }
        }

        setEducation(values);
    };

    const isFormValid = () => {
        return education.every(edu => (
            edu.degree &&
            edu.institution &&
            edu.program &&
            edu.fieldOfStudy &&
            edu.cgpa
        ));
    };

    const addFileUpload = (index) => {
        const values = [...education];
        const fileUploadsCopy = [...fileUploads];
        fileUploadsCopy[index].push(null);
        setFileUploads(fileUploadsCopy);
        values[index].fileUploads.push(null);
        setEducation(values);
    };

    const removeFileUpload = (index, fileIndex) => {
        const values = [...education];
        const fileUploadsCopy = [...fileUploads];
        fileUploadsCopy[index].splice(fileIndex, 1);
        setFileUploads(fileUploadsCopy);
        values[index].fileUploads.splice(fileIndex, 1);
        setEducation(values);
    };

    const handleCheckboxChange = (index, event) => {
        const values = [...education];
        values[index].currentlyStudying = event.target.checked;
        setEducation(values);
    };

    const removeEducation = (index) => {
        const values = [...education];
        values.splice(index, 1);
        const updatedValues = values.map((edu, i) => ({
            ...edu,
            id: i + 1,
        }));
        setEducation(updatedValues);
        setEducationCounter(updatedValues.length + 1);
    };

    const saveData = async () => {
        const user_id = localStorage.getItem("user_id")
        const data = new FormData();
        let sslc = null;
        let puc = null;
        let grad = null;

        let schoolName
        let schoolStartDate
        let schoolEndDate
        let schoolDegree
        let schoolFieldOfStudy
        let schoolPercentage

        let puName
        let puStartDate
        let puEndDate
        let puDegree
        let puFieldOfStudy
        let puPercentage

        let gradName
        let gradStartDate
        let gradEndDate
        let gradDegree
        let gradFieldOfStudy
        let gradCGPA
        let gradEntranceScore
        education.map((ed, index)=>{
            if(index === 0){
                sslc = ed.file;
                schoolName = ed.institution
                schoolStartDate = ed.startDate
                schoolEndDate = ed.graduationDate
                schoolDegree = ed.program
                schoolFieldOfStudy = ed.fieldOfStudy
                schoolPercentage = ed.cgpa
            }
            if(index === 1){
                puc = ed.file
                puName = ed.institution
                puStartDate = ed.startDate
                puEndDate = ed.graduationDate
                puDegree = ed.program
                puFieldOfStudy = ed.fieldOfStudy
                puPercentage = ed.cgpa
            }
            if(index === 2){
                grad = ed.fileUploads;
                gradName = ed.institution
                gradStartDate = ed.startDate
                gradEndDate = ed.graduationDate
                gradDegree = ed.program
                gradFieldOfStudy = ed.fieldOfStudy
                gradCGPA = ed.cgpa
                gradEntranceScore = ed.compscore
            }
        }) 
        const educationBody = {
            user_id: user_id,
            school: {
              name: schoolName,
              startDate: schoolStartDate,
              endDate: schoolEndDate,
              degree: schoolDegree,
              fieldOfStudy: schoolFieldOfStudy,
              percentage: schoolPercentage
            },
            pu: {
              name: puName,
              startDate: puStartDate,
              endDate: puEndDate,
              degree: puDegree,
              fieldOfStudy: puFieldOfStudy,
              percentage: puPercentage
            },
            grad: {
              name: gradName,
              startDate: gradStartDate,
              endDate: gradEndDate,
              degree: gradDegree,
              fieldOfStudy: gradFieldOfStudy,
              CGPA: gradCGPA,
              entranceScore: gradEntranceScore
            }
        }

        try{
            await axios.post(apiUrl+'/application/uploadAcademicDetails', educationBody)
        }catch(e){
            console.log(`error inserting education data : \n ${e}`)
        }
        data.append('userid', user_id);
        console.log(sslc);
        console.log(puc);
        if(sslc !== null){
            data.append('sslc', sslc);
        }
        if(puc !== null){
            data.append('puc', puc);
        }
        if(grad){
            grad.map((file, index) => {
                let i = index + 1
                const key = 'semester_' + i.toString();
                data.append(key, file);
            })
        }
        console.log(data)
        try {
            const response = await axios.post(apiUrl+'/uploadFiles', data);
            console.log(response.data);

        } catch (error) {
            console.error('Error sending form data:', error);
        }
        
    };

    return (
        <div className='heading'>
            <form>
                <h2>Scholarship Application</h2>
                <div className='progress' style={{ position: "relative" }}>
                    <ul>
                        <li><div id='step1'>Personal Details</div></li>
                        <li><div id='step2' >Academic Details</div></li>
                        <li style={{ backgroundColor: "white" }}><div id='step3'>Family Details</div></li>
                        <li style={{ backgroundColor: "white" }}><div id='step4'>Bank Details</div></li>
                        <div className="progressdiv">
                            <progress value={0.5} color='blue' />
                        </div>
                    </ul>
                </div>
                <div>

                    {education.map((edu, index) => (
                        <div key={edu.id}>
                            <h3 style={{ marginTop: '20px' }} >Education {edu.id}</h3>
                            <div className="box-container">

                                <label htmlFor="degree">Select Degree <span className="required">*</span></label>
                                <select
                                    name="degree"
                                    id="degree"
                                    value={edu.degree}
                                    onChange={(event) => handleChange(index, event)}>
                                    <option value="">Select </option>
                                    <option value="10th / SSLC">10th / SSLC/ICSE/CBSE</option>
                                    <option value="11th / 12th / PUC / Diploma Courses">11th / 12th / PUC /ICSE/CBSE/ Diploma Courses </option>
                                    <option value="Bcom / Bsc / BA / BBM / CPT / B Pharma">Bcom / Bsc / BA / BBM / CPT / B Pharma </option>
                                    <option value="Mcom / Msc / MA / MBA / B Ed / M Pharma">Mcom / Msc / MA / MBA / B Ed / M Pharma </option>
                                    <option value="Engineering / MBBS">Engineering / MBBS </option>
                                    <option value="MS / MD / Phd">MS / MD / Phd</option>
                                    <option value="ICWA / ACS / CA (IPCC) / IAS">ICWA / ACS / CA (IPCC) / IAS</option>

                                </select>
                            </div>
                            <div className="form-group">
                                <label htmlFor="institution">Name of the Institution <span className="required">*</span></label>
                                <input
                                    type="text"
                                    id="institution"
                                    name="institution"
                                    value={edu.institution}
                                    onChange={(event) => handleChange(index, event)}
                                />
                            </div>

                            <div className="checkbox">
                                <label htmlFor="currentlyStudying"> Currently Studying?</label>
                                <input
                                    type="checkbox"
                                    id="currentlyStudying"
                                    name="currentlyStudying"
                                    checked={edu.currentlyStudying}
                                    onChange={(event) => handleCheckboxChange(index, event)}
                                />
                            </div>
                            {edu.currentlyStudying && (
                                <div className='form'>
                                    <div className="field">
                                        <label htmlFor="">Start date</label>
                                        <input
                                            type="date"
                                            name="startDate"
                                            value={edu.startDate}
                                            onChange={(event) => handleChange(index, event)}
                                        />
                                    </div>

                                    <div className="field">
                                        <label htmlFor="">Graduation date</label>
                                        <input
                                            type="date"
                                            name="graduationDate"
                                            value={edu.graduationDate}
                                            onChange={(event) => handleChange(index, event)}
                                        />
                                    </div>
                                </div>
                            )}

                            <div className="box-container">

                                <label htmlFor="program">Program / Degree<span className="required">*</span></label>
                                <select
                                    name="program"
                                    id="program"
                                    value={edu.program}
                                    onChange={(event) => handleChange(index, event)}>
                                    <option value="">Select </option>
                                    <option value="10th / SSLC">10th / SSLC/ICSE/CBSE</option>
                                    <option value="11th">11th/ICSE/CBSE</option>
                                    <option value="12th">12th/ICSE/CBSE</option>
                                    <option value="PUC">PUC/ICSE/CBSE</option>
                                    <option value="Diploma Courses">Diploma Courses </option>
                                    <option value="Bcom">Bcom</option>
                                    <option value="Bsc">Bsc</option>
                                    <option value="BA">BA</option>
                                    <option value="BBM">BBM</option>
                                    <option value="CPT">CPT</option>
                                    <option value="B Pharma">B Pharma</option>
                                    <option value="Mcom">Mcom</option>
                                    <option value="Msc">Msc</option>
                                    <option value="MA">MA</option>
                                    <option value="MBA">MBA</option>
                                    <option value="B Ed"> Ed</option>
                                    <option value="M Pharma">M Pharma</option>
                                    <option value="Engineering">Engineering</option>
                                    <option value="MBBS">MBBS</option>
                                    <option value="MS">MS</option>
                                    <option value="MD">MD</option>
                                    <option value="Phd">Phd</option>
                                    <option value="ICWA">ICWA</option>
                                    <option value="ACS">ACS</option>
                                    <option value="CA (IPCC)">CA (IPCC)</option>
                                    <option value="IAS">IAS</option>
                                </select>
                            </div>

                            <div className='form'>
                                <div className="form-group field">
                                    <label htmlFor="fieldOfStudy">Field of Study<span className="required">*</span></label>
                                    <input
                                        type="text"
                                        name="fieldOfStudy"
                                        value={edu.fieldOfStudy}
                                        onChange={(event) => handleChange(index, event)}
                                        placeholder='eg: Computer Science'
                                    />
                                </div>

                                <div className="form-group field">
                                    <label htmlFor="cgpa">CGPA / Percentage<span className="required">*</span></label>
                                    <input
                                        type="number"
                                        name="cgpa"
                                        value={edu.cgpa}
                                        onChange={(event) => handleChange(index, event)}
                                    />
                                </div>
                            </div>

                            {(edu.degree === "10th / SSLC" ) && (
                                    <div>
                                        <div className="form-group">
                                            <div style={{ display: 'flex', alignItems: 'center' }}>
                                                <label htmlFor="file">
                                                   Upload your previous 3 years Markscard
                                                    {edu.degree === "10th / SSLC" || edu.degree === "11th / 12th / PUC / Diploma Courses" ? <span className="required">*</span> : null}
                                                </label>
                                                <div>
                                                    <label htmlFor="" className="redLabel23">(only in pdf, png, jpg and jpeg format and max size should be 1MB)</label>
                                                </div>
                                            </div>
                                            <input
                                                type="file"
                                                className='academic_files'
                                                name="file"
                                                accept='.pdf,.png,.jpg,.jpeg'
                                                // value={edu.file}
                                                onChange={(event) => handleChange(index, event)}
                                                required={edu.degree === "10th / SSLC" || edu.degree === "11th / 12th / PUC / Diploma Courses"}
                                            />
                                              <input
                                                type="file"
                                                className='academic_files'
                                                name="file"
                                                accept='.pdf,.png,.jpg,.jpeg'
                                                // value={edu.file}
                                                onChange={(event) => handleChange(index, event)}
                                                required={edu.degree === "10th / SSLC" || edu.degree === "11th / 12th / PUC / Diploma Courses"}
                                            />
                                              <input
                                                type="file"
                                                className='academic_files'
                                                name="file"
                                                accept='.pdf,.png,.jpg,.jpeg'
                                                // value={edu.file}
                                                onChange={(event) => handleChange(index, event)}
                                                required={edu.degree === "10th / SSLC" || edu.degree === "11th / 12th / PUC / Diploma Courses"}
                                            />
                                        </div>
                                    </div>
                                )}
  {(edu.degree === "11th / 12th / PUC / Diploma Courses") && (
                                    <div>
                                        <div className="form-group">
                                            <div style={{ display: 'flex', alignItems: 'center' }}>
                                                <label htmlFor="file">
                                                    Upload your !10th/SSLC/ICSE/CBSE Markscard
                                                    {edu.degree === "10th / SSLC" || edu.degree === "11th / 12th / PUC / Diploma Courses" ? <span className="required">*</span> : null}
                                                </label>
                                                <div>
                                                    <label htmlFor="" className="redLabel23">(only in pdf, png, jpg and jpeg format and max size should be 1MB)</label>
                                                </div>
                                            </div>
                                            <input
                                                type="file"
                                                className='academic_files'
                                                name="file"
                                                accept='.pdf,.png,.jpg,.jpeg'
                                                // value={edu.file}
                                                onChange={(event) => handleChange(index, event)}
                                                required={edu.degree === "10th / SSLC" || edu.degree === "11th / 12th / PUC / Diploma Courses"}
                                            />
                                        </div>
                                    </div>
                                )}
                            {(edu.degree === "Bcom / Bsc / BA / BBM / CPT / B Pharma" ||
                                edu.degree === "Mcom / Msc / MA / MBA / B Ed / M Pharma" ||
                                edu.degree === "Engineering / MBBS" ||
                                edu.degree === "MS / MD / Phd" ||
                                edu.degree === "ICWA / ACS / CA (IPCC) / IAS") && (
                                    <div>
                                        <div className="form-group">
                                            <label htmlFor="compscore">
                                                Marks scored on an entrance exam
                                            </label>
                                            <input
                                                type="text"
                                                name="compscore"
                                                value={edu.compscore}
                                                onChange={(event) => handleChange(index, event)}
                                                required
                                            />
                                        </div>
                                        <p className="disclaimer" style={{ marginBottom: '10px' }}>Please upload all previous semester documents. To upload files, kindly click on the 'Add File' button.</p>
                                        {edu.fileUploads.map((file, fileIndex) => (
                                <div key={fileIndex} className="form-group">
                                    <div style={{ display: 'flex', alignItems: 'center' }}>
                                        <label htmlFor={`file-${index}-${fileIndex}`}>
                                            Upload file
                                            <span className="required">*</span>
                                        </label>
                                        <div>
                                            <label htmlFor="" className="redLabel23">(only in pdf, png, jpg and jpeg format and max size should be 1MB)</label>
                                        </div>
                                    </div>
                                    <input
                                        type="file"
                                        className='academic_files'
                                        name={`file-${fileIndex}`}
                                        accept='.pdf,.png,.jpg,.jpeg'
                                        onChange={(event) => handleChange(index, event)}
                                        data-file-index={fileIndex}
                                        required
                                    />
                                    {fileUploads[index].length > 0 && (
                                        <button style={{ marginBottom: '20px' }} type="button" className="delete" onClick={() => removeFileUpload(index, fileIndex)}>
                                            Delete file
                                        </button>
                                    )}
                                            </div>
                                        ))}
                                        <button type="button" onClick={() => addFileUpload(index)}>
                                            Add file
                                        </button>
                                    </div>
                                )}

                            <input type="button" value=" Delete Education" onClick={() => removeEducation(index)} />
                        </div>
                    ))}

                    <input type="button" value="Add Education" onClick={addEducation} />

                    <p className="disclaimer">Eligibility Criteria: Applicants must have scored above 80% in SSLC and 70% or above in PUC/Graduation to be eligible for this scholarship.</p>

                    <div className="button-container">
                        <button onClick={() => {
                            saveData();
                            onPrevious();
                        }} type="submit2" value="Submit">Previous</button>
                        <button
                            onClick={() => {
                                saveData();
                                onNext();
                            }}
                            // onClick={(event) => {
                            //     if (validateForm()) {
                            //         // window.alert('Education details have been added successfully!');
                            //         event.preventDefault();
                            //         // event.target.nextSibling.click();
                            //         onNext();
                            //     }
                            // }}
                            type="submit3" value="Submit"
                        disabled={!isFormValid()}
                        >
                            Next</button>
                    </div>
                </div>
            </form >

        </div >
    );
}

export default MyForm;
