import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom'; // Replace Switch with Routes
import Header from './components/Header';
import About_trustees from './components/About_trustees';
import About from './components/About';
import Footer from './components/Footer';
import MergedPages from './components/MergedPages';
import Status from './components/Status';
import Contact from './components/Contactus';
import Donate from './components/donate';

import './App.css';

const App = () => {
  return (
    <Router>
      <div>
        <Header />
        <Routes> {/* Use Routes instead of Switch */}
          <Route path="/status" element={<Status />} />
          <Route path="/contact" element={<Contact />} />
          <Route path="/donate" element={<Donate />} />
          {/* <Route path="/about" element={<About />} /> */}
          <Route path="/" element={
            <>
              <MergedPages />
              <About />
              <About_trustees />
              {/* <Footer/> */}
            </>
          } />
        </Routes>
        <Footer />
      </div>
    </Router>
  );
};

export default App;
